import Vue from 'vue'
import { Message, MessageBox } from 'element-ui';
import i18n from '@/locales'
import router from "@/router"
import moment from 'moment';

const state = () => ({
  barcodeType: 0, // 0=sipariş, 1=ürün
  openDeliveryDatePopupInfo: false,
  openDeliveryDatePopupDays: "",
  refundInformation: {
    order: {
      products: []
    }
  },
  readedProductList: [], //iade için okutulan ürünler
  returnInformation: { //iade isteği için atılıcak değerler
    returnPaymentType: '-1',
    cargoReturn: false,
    ReflectFixedShippingAmount: false,
    payDoorReturn: false,
    ReduceShippingCostAmount: 0,
    iban: '',
    ibanName: ''
  },
  returnReason: null, //ürünlerin içine atılıcak iade sebebi
})

// getters
const getters = {
  activeOrder(state) {
    return state.refundInformation.order
  },
  receivedStatus(state) {
    if (state.refundInformation.order.status < 7 ||
      state.refundInformation.order.status == 18) {
      return false //teslim alınmamış
    } else {
      return true
    }
  },
  productList(state) {
    return state.refundInformation.order.products.filter(item => item.returnPiece != item.piece)
  },
  refundProductList(state) {
    return state.refundInformation.order.products.filter(item => item.returnPiece > 0)
  },
  returnCauses(state) {
    return state.refundInformation.returnCausies
  },
  returnTypes(state) {
    return state.refundInformation.returnType
  },
  easyReturn(state) {
    return state.refundInformation.returnOrderRequest
  },
  openDeliveryDatePopup(state) {
    return state.openDeliveryDatePopupInfo
  },
  openDeliveryDatePopupDays(state) {
    return state.openDeliveryDatePopupDays
  }
}

// actions
const actions = {

  async getOrder({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get('/api/return-orders/barcodes/' + this.state.app.barcode)
      commit('setRefundInformation', response.data.model)
      commit('changeBarcodeType', 1)
      if (response.data.model.isCampaignOrder) {
        MessageBox.alert(i18n.t('SiparisteKampanyaUygulanmistir'), i18n.t('Dikkat'), {
          center: true,
          type: 'warning',
          confirmButtonText: i18n.t('Tamam')
        })
      }
      if (response.data.model.order.giftVoucherAmount > 0) {
        MessageBox.alert(
          i18n.t("SiparisteHediyeCekiUygulanmistir"),
          i18n.t("Dikkat"),
          {
            center: true,
            type: "warning",
            confirmButtonText: i18n.t("Tamam"),
          }
        );
      }

      let currentDeliveryDate = moment(response.data.model.order.deliveryDate, "DD.MM-YYYY").format("YYYY-MM-DD");
      let deliveryEndDate = moment(currentDeliveryDate, "YYYY-MM-DD").add(this.state.user.settings.iadeAyar.numberOfDaysForReturningOrder, 'days').format("YYYY-MM-DD");
      let now = moment(new Date()); //todays date

      let lastDateCount = now.diff(deliveryEndDate, "days")
      if (lastDateCount > 0) { //eğer iade tarihi geçmişse
        commit('deliveryInfoPopup', { status: true, pastDateCount: lastDateCount })
      }

    } catch (error) {
      if (error.response.data.Key == 'ORDER_IS_NOT_FOUND') {
        Message({ type: 'warning', message: i18n.t('ORDER_IS_NOT_FOUND') })
      }
      // console.log(error)
    }
  },
  async completeRefund({ getters, state, commit }) {
    if (this.state.user.settings.iadeAyar.iadeNedeniAktif && state.returnReason) {
      state.refundInformation.order.products.map(item => item.returnReasonID = state.returnReason)
    }
    try {
      state.returnInformation.returnPaymentType = parseInt(state.returnInformation.returnPaymentType)
      const response = await Vue.prototype.$axios.put(`/api/return-orders/${getters.activeOrder.id}/completed`, {
        ...state.returnInformation,
        products: getters.refundProductList,
        deliveryBeforeReturn: !getters.receivedStatus,
      })
      Message({ type: 'success', message: i18n.t('BasariylaIadeEdildi') })
      commit('resetRefund')
      window.location.reload();
    } catch (error) {
      console.log(error)
    }
  }


}

// mutations
const mutations = {
  changeBarcodeType(state, payload) {
    state.barcodeType = payload
  },
  deliveryInfoPopup(state, payload) {
    state.openDeliveryDatePopupInfo = payload.status
    state.openDeliveryDatePopupDays = payload.pastDateCount
  },
  deliveryInfoPopupClose(state, payload) {
    state.openDeliveryDatePopupInfo = false
  },
  setRefundInformation(state, payload) {
    state.refundInformation = payload
    if (payload.returnOrderRequest) {

      state.returnInformation.iban = payload.returnOrderRequest.iban
      state.returnInformation.ibanName = payload.returnOrderRequest.ibanName
    }
    payload.order.refundDoorPayment = 0
    payload.order.refundDoorPayment += parseFloat(payload.order.payments.map(item => item.payDoorAmount))
  },
  resetRefund(state) {
    // state.refundInformation = {}
    state.readedProductList = []
    state.returnInformation = {
      returnPaymentType: '-1',
      cargoReturn: false,
      ReflectFixedShippingAmount: false,
      ReduceShippingCostAmount: 0,
      payDoorReturn: false,
      iban: null,
      ibanName: null
    }
    this.commit('refund/changeBarcodeType', 0)
  },
  readProduct(state) {
    //önce ürün listesinde var mı bak
    let findProductIndexInProductList = state.refundInformation.order.products.findIndex(item => item.barcode == this.state.app.barcode)
    let isSameBarcode = state.refundInformation.order.products.some(item => item.barcode == this.state.app.barcode)
    let findedProduct = state.refundInformation.order.products[findProductIndexInProductList]

    //aynı barkodlu ürünler
    console.log("i", isSameBarcode)
    if (isSameBarcode) {
      let currentIndex = 0;
      let activeProductLength = state.refundInformation.order.products.filter(item => item.returnPiece != item.piece).length

      if (activeProductLength == 0) {
        return
      }
      if (state.refundInformation.order.products[currentIndex].returnPiece == state.refundInformation.order.products[currentIndex].piece) {
        currentIndex += 1;
      }

      let findedProduct = state.refundInformation.order.products[currentIndex]
      if (findedProduct.piece == findedProduct.returnPiece) { //iade edilenle maksimum ürün sayısı eşitse
        findedProduct.returnPiece = 0
      }
      findedProduct.returnPiece += 1
    }

    else {
      if (findProductIndexInProductList >= 0) {
        if (findedProduct.piece == findedProduct.returnPiece) { //iade edilenle maksimum ürün sayısı eşitse
          return Message({ type: 'warning', message: i18n.t('UrunBulunamadi') })
        }
        findedProduct.returnPiece += 1
      } else {
        return Message({ type: 'warning', message: i18n.t('UrunBulunamadi') })
      }
    }

  },
  refundAllProducts(state) {
    state.refundInformation.order.products.map(item => item.returnPiece = item.piece)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
export default [
  {
    date: '16.01.2024',
    version: '6.7.4',
    list: [
      'Bildirim Sistemi geliştirildi.',
      'Raf hareketleri çalışması geliştirildi, log kaydı eklendi.',
      'Ortak Barkod ayarı geliştirildi ve buna göre çıktı alınması sağlandı.',
      'Faturası Kesilen Siparişler "Depo" bazlı filtreleme geliştirildi',
      'Karışık Siparişler Info eklendi.',
      'Depolararası Transfer Alıcı Depo Web Stok arttırma hatası giderildi.',
      'Kargo kontrol sayfası yetkiye bağlandı.',
      'Eksik Ürün Birleştirme barkod girmeden masa görülmesi geliştirildi.',
      'Depolararası Transfer ekranına ürünlerin takip edilebileceği bar geliştirildi.'
    ]
  },
  {
    date: '18.12.2024',
    version: '6.7.3',
    list: [
      'Ürün Yerleştirme Raporu ve İade Alınan Ürünler Raporuna Tedarikçi bilgisi eklendi.',
      'Ürün Detay Aloke Stok adet bilgisi eklendi.',
      'Sipariş Notu ve Hediye Paketi ayara bağlanarak , kalite kontrol adımında gösterilmesi sağlandı.',
      'Hatalı Stok Raporu filtre geliştirildi.',
      'Ürün Yerleştirme ekranına Raf Stok bilgisi eklendi.',
      'Set Yönetimi Sipariş Tarihi eklendi.',
      'Login ekranında default kullanıcı bilgisi kaydedilmesi hatası giderildi.',
      'Kargo Kontrol işleminde log kaydı atıldı.',
      'Kargo Çıktısı Al ekranında farklı Paket ID hatası giderildi.',
      'OMS farklı mağazaya atanmış ürünün kalite kontrol çıktısında gelmesi hatası giderildi.',
      'Ayara bağlı onay olmadan direkt rafa ürün yerleştirme yapılması geliştirildi.'
    ]
  },
  {
    date: '04.12.2024',
    version: '6.7.2',
    list: [
      'Rafı Toplamaya kapat seçeneği eklendi.',
      'Sipariş Bazlı toplamada Ürünü Değiştir hatası giderildi.',
      'Siparişlerin hangi süre ile toplanabileceğinin yönetileceği bir alan geliştirildi.',
      'Ürün detay Konsinye düzenleme işlemi yetkiye bağlandı.',
      'Raporlarda Depo bazlı data gelmesi geliştirildi.',
      'OMS depoları için rapor geliştirildi.',
      'Market kullanıcılarında müşteri arama hatası giderildi.',
      'Hepsipay ödemelerinde iade seçenekleri eklendi.',
      'Mal Kabul ürün okutmada ses hatası giderildi.',
      'Kalite kontol ekranında Pop-Up ile sipariş notu gösterilmesi yapıldı.',
      'Fatura çıktısında saat/tarih hatası giderildi.',
      'Sipariş PDF ayarındaki hata giderildi.'
    ]
  },
  {
    date: '21.11.2024',
    version: '6.7.1',
    list: [
      'Rafı toplamaya kapat seçeneği geliştirildi.',
      'Sipariş Birleştirmede çok ürünlü siparişe kalite kontrol özelliği geliştirildi.',
      'İade Süresi geçen siparişlerin iadesinde bilgilendirme eklendi . İade süresi ayarlardan eklenmelidir.',
      'Ürünler raporunda farklı varyasyonlarda doğru görsel gelmeme hatası giderildi.',
      'Kalite kontrol ekranına Ödeme Tipi bilgisi eklendi.',
      'Sayım Dosyaları Raporlarına varyasyon bilgileri eklendi.',
      'Faturası Kesilen Siparişler raporu "Excel" çıktısındaki hata giderildi.',
      'Sipairş Detay "Kargo Entegrasyon" güncelleme geliştirmesi yapıldı.',
      'Temsilci Ekleme depo hatası giderildi.',
      'Toplu sayımda adet hatası giderildi .',
      'Sayımda "0" olarak güncellenen ürün için hareketlere log atılması geliştirildi.',
      'Raporlarda ürün filtrelemesinde aynı anda 1+ ürün eklenmesi geliştirildi.'
    ]
  },
  {
    date: '06.11.2024',
    version: '6.7.0',
    list: [
      'İade süresi geçen siparişlerin iadesi için işlem öncesi bilgilendirme ekranı çalışıldı.',
      'Ürün Detay ekranına ürünün bulunduğu siparişler ekranı çalışıldı.',
      'Sipariş Seçim sayfasında performans iyileştirmesi yapıldı.',
      'Rapor data ve export hataları giderildi.',
      'Sipariş Durumu değiştirmesi için Log kaydı eklendi.',
      'Mobil ekranlarda düzenlemeler yapıldı.',
      'İade işlemlerinde Ödeme Bilgisi log kaydı atılması çalışıldı.'
    ]
  },
  {
    date: '23.10.2024',
    version: '6.6.9',
    list: [
      'Depo güncellemesinde hemen yansımama hatası giderildi.',
      'Rapor geliştirmeleri yapıldı.',
      'Ayara bağlı olarak ürün bazlı işlem sıfırla özelliği geliştirildi.',
      'Rapor filtrelemelerinde yaşanan hatalar giderildi.',
      'İade ekranında Hediye Çeki tutarı eklendi.',
      'OMS kullanıcılarında siparişlerin tüm mağazalara dağıtılma hatası giderildi.'
    ]
  },
  {
    date: '09.10.2024',
    version: '6.6.8',
    list: [
      'Sipariş PDF çıktısı ayara bağlandı.',
      'WMS kargo takip panelde fixlendi.',
      'Siparişe Ürün Ekle alanı fixlendi.',
      'Excel Dosyayı Gör yetkiye bağlandı.',
      'Sayım Rafına "Rafı Tamamla" seçeneği eklendi.',
      'Rapor filtreleri düzenlendi.',
      'Raporlara yeni kolonlar eklendi.',
      'Depolararası transferde "Mal Kabul Alanı" rafının gelmemesi ayara bağlı olarak düzenlendi.',
      'Terminal onay sesi olmayan alanlara eklendi.',
      'Ana Peron ismi ile Raf Boşaltıldığında altındaki peronlar için de işlem yapmama sorunu fixlendi.',
      'Tüm sayım dosyalarının olduğu bir rapor eklendi.'
    ]
  },
  {
    date: '01.10.2024',
    version: '6.6.7',
    list: [
      'Depolararası Transfer Bulamadım kurgusu çalışıldı.',
      'Depolararsı Transfer Eksik Ürün Yönetimi ekranı çalışıldı.',
      'Konsinye ürün bugfix yapıldı.',
      'Rapor Düzenlemeleri yapıldı.',
      'Geçmiş Stok Raporu filtrelemeleri geliştirildi.',
      'Çoklu ürün seçilerek raporlarda filtreleme yapılması geliştirildi.',
      'Toplu ürün Sipariş Birleştirmesi ayarı çalışıldı.',
      'Ürün Bazlı toplama grubunun varyasyon ayrımı olmadan listelenmesi sağlandı.',
      'Sayım Dosyasına gelmeyen raf durumu fixlendi.',
      'Ürün Bazlı Sayım Raporu çalışıldı.'
    ]
  },
  {
    date: '27.08.2024',
    version: '6.6.6',
    list: [
      'Rapor iyileştirmeleri yapıldı.',
      'Kalite Kontrol ekranı hata giderildi.',
      'Tüm depoların görülebileceği yönetici ekranı yapıldı.',
      'Ürün bazlı toplama listesindeki hatalar giderildi.',
      'Mal Kabul Yönetim ekranında düzenlemeler yapıldı.',
      'Login ekranı hata giderildi.',
      'Sayım dosyası oluşturmada hatalar giderildi.',
      'Excel yönetimi iyileştirmeler yapıldı.'
    ]
  },
  {
    date: '14.08.2024',
    version: '6.6.5',
    list: [
      'Eksik ürün işaretleme kurgusunda düzenlemeler yapıldı',
      'Toplu işlemler için ayar eklendi.',
      'Ana lokasyon bazlı toplama grubuna Tekli/Çoklu seçenekleri de eklendi.',
      'Rapor geliştirmeleri yapıldı.'
    ]
  },
  {
    date: '17.07.2024',
    version: '6.6.4',
    list: [
      'Rapor düzenlemeleri yapıldı.',
      'Ürün Hareketleri Sipariş ID yönlendirmesi çalışıldı.',
      'Kargoya Verilen Siparişler raporu Kargo Tutarı bilgisi eklendi.'
    ]
  },
  {
    date: '03.07.2024',
    version: '6.6.3',
    list: [
      'Rapor filtre geliştirmeleri yapıldı.',
      'Raf Export geliştirmesi yapıldı.',
      'Sayım dosyasındaki buglar temizlendi.',
      'Set oluşturma hataları düzenlendi.',
      'Faturası Kesilmeyen Siparişler Raporu geliştiridi.',
      'Ürün detay diğer varyasyon yönlendirmesi geliştirildi.',
      'Ana Lokasyon Bazlı Toplama listesi geliştirildi.'
    ]
  },
  {
    date: '04.06.2024',
    version: '6.6.2',
    list: [
      'Rapor geliştirmeleri yapıldı.',
      'Sipariş Seçim listeleri iyileştirildi.',
      'Mal Kabul Yönetimi ekranı iyileştirildi.',
      'Eksik ürün senaryoları geliştirildi.'
    ]
  },
  {
    date: '20.05.2024',
    version: '6.6.1',
    list: [
      'Yeni rapor listeleri eklendi.',
      'Depolararası Transfer Ürün listelemesi güncellendi.',
      'Sipariş Seçim listelerine Info Pop-Up eklendi.',
      'Rapor filtrelenmeleri geliştirildi.'
    ]
  },
  {
    date: '28.03.2024',
    version: '6.5.5',
    list: ['Sayım dosyalarına excel indirme eklendi.', 'Rapor filtrelerinde geliştirmeler yapıldı.']
  },
  {
    date: '15.03.2024',
    version: '6.5.4',
    list: [
      'İade ekranına sipariş ödeme bilgileri eklendi.',
      'İade Raporlarına yeni filtreler eklendi.',
      'Stok Raporlarına yeni filtreler eklendi.',
      'Raf Bazlı Stok Raporları sayfası eklendi ve bu değişikle birlikte stok raporları için ayrı bir menü oluşturuldu.',
      'Depolar Arası Transfer için anasayfaya grafik eklendi.'
    ]
  },
  {
    date: '22.02.2024',
    version: '6.5.3',
    list: [
      'Rafa Ürün Yerleştirme raporu eklendi.',
      'Yeni talep oluşturma sayfası düzenlendi.',
      'İade Alınan Ürünler RAporuna yeni filtreler eklendi.',
      'Depolar Arası Trasnfer listesine ürün arayarak filtreleme özelliği eklendi.',
      'Sipariş listesine konsinye filtresi eklendi.',
      'Rafta olmayan ürünler raporuna tarih ve kargo kolonu eklendi.',
      'Sipariş detayına ürün varyasyon bilgileri eklendi.'
    ]
  },
  {
    date: '05.02.2024',
    version: '6.5.2',
    list: [
      'Rafta olmayan ürünler raporuna konsünye filtresi eklendi',
      'Hatalı stok raporları eklendi',
      'Sayım dosyasına raf bazlı ürün görme özellği eklendi'
    ]
  },
  {
    date: '31.01.2024',
    version: '6.5.0',
    list: ['Araba listesine durum filtresi eklendi.', 'Eksik ürün listesi tasarımı geliştirildi.', 'Sayım modülü geliştirildi']
  },
  {
    date: '17.01.2024',
    version: '6.4.0',
    list: ['Performans iyileştirmeleri.']
  },
  {
    date: '30.10.2023',
    version: '6.3.1',
    list: ['Sipariş listesi sayfalama özelliği değiştirildi. (Aşağı kaydırarak otomatik olarak sayfa değiştirebilirsiniz)']
  },
  {
    date: '25.10.2023',
    version: '6.3.0',
    list: ['Özel Yazıcı ayarları eklendi.', 'Çalışanlara mail adresi kısmı eklendi. (Yeni eklenen kişilerde bu bilgi zorunludur)']
  },
  {
    date: '04.10.2023',
    version: '6.2.6',
    list: [
      'Hediye paketi olan siparişler için kalite kontrole sesli uyarı eklendi.',
      'Toplama ekranlarında arayüz iyileştirmeleri yapıldı.',
      'Eksik ürün senaryolarında geliştirmeler yapıldı.'
    ]
  },
  {
    date: '12.09.2023',
    version: '6.2.5',
    list: [
      'Kullanıcı menüsü altına hesabınızla aldığınız son çıktıyı tekrar alabilmenize sağlayan bir buton eklendi. (Kağıt sıkışması, internet sorunu, yazıcı, sistemin yavaşlaması vb sorunların önüne geçmesi için tasarlandı)',
      'Mağaza ve Depo seçimi yapılan alanlara yazarak arama özelliği eklendi.'
    ]
  },
  {
    date: '29.08.2023',
    version: '6.2.4',
    list: [
      'Toplama sayfasına Parçalı Barkod okutma özelliği eklendi. Ayarlardan aktif edebilir ve kullanım talimatları için Ticimax Destek ile iletişime geçebilirsiniz.',
      'Kalite Kontrol sayfasına siparişte hediye notu varsa çalışanı uyarması için uyarı eklendi.'
    ]
  },
  {
    date: '16.08.2023',
    version: '6.2.3',
    list: [
      'Ürün toplama sayfasına toplanacak ürünleri çıktı alma özelliği eklendi.',
      'Operasyon işlemlerine kolay ulaşmak için her sayfaya adım yönetimi eklendi.',
      'Performans raporları sayfasına prim hesaplama modülü eklendi.',
      'Toplanacak Ürünler Raporuna ürünle alakalı ekstra detaylar eklendi.'
    ]
  },
  {
    date: '01.08.2023',
    version: '6.2.1',
    list: [
      'Kalite Kontrol sayfasına hediye notu yazdırma özelliği eklendi.',
      'Kargo Ayarları safyası yenilendi. Bu sayfadan kargo firmalarının depo gönderimini aktif/pasif yapabilirsiniz.',
      'Toplanacak Ürünler Raporuna yeni filtreler eklendi.',
      'Mobil terminallerde çıktı alınabilmesi için gerekli düzeltmeler yapıldı.'
    ]
  },
  {
    date: '24.07.2023',
    version: '6.2.0',
    list: [
      "Ürün/Sipariş toplamak için gerekli bütün işlemler 'Sipariş Yönetimi' menüsünden 'Operasyonel İşlemler' menüsü altına taşınmıştır. (Toplama Arabası, Sipariş Seçim, Ürün Toplama, Sipariş Birleştirme, Kalite Kontrol, Eksik Ürün Listesi, Eksik Ürün Birleştirme)",
      'Yeni set oluştururken sipariş arama filtreleri geliştirildi.',
      'Toplama ekranına toplanacak ürünleri excel çıktısı alabilmek için buton eklendi.',
      'Excel dışarı aktarırken dosyada olmasını istediğiniz kolonları özelleştirebileceğiniz bir yapı eklendi.',
      'Filtreleme bulunan bütün sayfalara filtre kaydetme özelliği eklendi.',
      'Kargoya verilen siparişlere yeni filtreler eklendi.',
      'Performans Raporları, Toplanan Ürünler ve Rafta Olmayan Ürünler raporlarında filtre geliştirmeleri yapıldı.'
    ]
  },
  {
    date: '05.07.2023',
    version: '6.1.5',
    list: [
      'Uygulama arayüzünde iyileştirmeler yapıldı.',
      'Sipariş seçim ekranından Lokasyon bazlı ya da Ürün bazlı toplama yapabilirsiniz.',
      'Hesabım sayfasından kendinize ait bir profil resmi ekleyebilirsiniz.',
      'Bütün depolardaki stokları görüntüleyebilmek için Depo Stok Raporu eklendi.',
      'Stok raporlarında isim değişiklikleri yapıldı.(stok raporları => stok hareket raporları / anlık stok raporları=> stok raporları)',
      'Mağaza yönetim sayfası ayarlar altına eklendi. Bu sayfadan mağaza ve depo ekleyebilir,düzenleyebilir ve silebilirsiniz. Görüntüleyebilmek için Yetki Yönetimi=>Ayarlar kısmından Mağaza Yönetimi yetkisi açılmaslı.',
      'Eksik ürün senaryolarında geliştirmeler yapıldı.'
    ]
  },
  {
    date: '06.06.2023',
    version: '6.1.4',
    list: [
      "Mal Kabul Yönetimi'yle alakalı bütün sayfalar değiştirildi ve süreçler geliştirildi.",
      'Excel ile rafa ürün ekleme özelliği geliştirildi.'
    ]
  },
  {
    date: '24.05.2023',
    version: '6.1.3',
    list: [
      'İade sayfası tamamen yenilendi.',
      'Sipariş Seçim sayfasına bütün pazar yerleri gelecek şekilde düzenleme yapıldı, ödeme tipine göre sipariş toplama özelliği eklendi. (Yetki yönetimi sayfasından yeni yetkileri lütfen kontrol ediniz.)',
      'Depo Ayarları tamamen yenilendi.',
      'Kargo Ayarları tamamen yenilendi.'
    ]
  },
  {
    date: '16.05.2023',
    version: '6.1.2',
    list: [
      'Sipariş Birleştirme sayfası tamamen yenilendi ve performans iyileştirmeleri yapıldı.',
      'Performans Raporları sayfayı yenilendi.',
      'Fazla Ürün Yönetimi sayfası yenilendi.',
      'Bütün rapor sayfalarına Excel çıktı alma özelliği eklendi. Excel dosyalarını görmek için Yetki Yönetimi=>Süreç Yönetimi=>Excel İşleri yetkisini açmalısınız.'
    ]
  },
  {
    date: '03.05.2023',
    version: '6.1.0',
    list: [
      'Anasayfa tasarımı yenilendi, istatistikler iyileştirildi. (Lütfen yetki yönetimi sayfasından yeni yetkileri kontrol ediniz.)',
      'Kargoya Verilen Siparişler için rapor eklendi. (Sayfayı görmek için yetki yönetiminden ilgili yetkiyi aktif edebilirsiniz.)',
      'Faturası Kesilen Siparişler raporu filtreleri geliştirildi. Artık tek 1 gün ya da belirli aralıklarda bir tarhi seçebilirsiniz.',
      'Anlık Stok Raporu, ürün raporları altına eklendi. (Stok raporları yetkiniz açıksa sayfaya ulaşabilirsiniz.)',
      'Sipariş detay sayfasına sipariş durumunu değiştirme özelliği eklendi. (Butonu görmek için yetki yönetiminden ilgili yetkiyi aktif edebilirsiniz.)',
      'Masadaki kolilere durum filtresi eklendi.',
      'Raf detay sayfalarına o rafa ait stok bilgisi eklendi. (Bu billgiyi görmek için ürünleri göster demeniz gerekmektedir.)',
      'Artık bir raftaki bütün ürünleri raf detayından "Hepsini Çıkart" diyerek silebilirsiniz. (Yetki yönetiminden Ürün Yönetimi=>Ürün Çıkarma=> Tüm ürünleri çıkar yetkisinin açık olması gerekmektedir.)',
      'Eksik Ürün Listesi sayfası yenilendi.',
      'Eksik Ürün Birleştirme sayfası yenilendi.',
      'Eksik ürün ve kalite kontrol süreçleri iyileştirildi.',
      'Ürün listesine varsayılan olarak sadece stoktaki ürünler gelecek şekilde düzenleme yapıldı.',
      'Hata düzeltmeleri ve performans iyileştirmeleri yapıldı.'
    ]
  }
]

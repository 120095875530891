import Vue from 'vue'
import { Message, MessageBox } from 'element-ui';
import i18n from '@/locales'
import router from "@/router"
import { beep } from '@/helpers/utils/voiceMessages'


export const state = () => ({
  barcodeType: 0, //0 raf - 1 ürün
  activeShelfId: null,
  fileDetail: {},
  shelfList: [],
  productList: [],
  isNotProductButton: false,
  isNotFoundProduct: false,
  isMoreNotFoundProduct: false,
  transferWarehouseId: '',
  pickCount: 0,
  allProductListAccordion: '',
  pickedProduct: "",
  readedProductList: []
})

const getters = {

}

const mutations = {
  changeBarcodeType(state, payload) {
    state.barcodeType = payload
  },
  setFileDetail: (state, payload) => {
    state.fileDetail = payload
  },
  transferWarehouseId: (state, payload) => {
    state.transferWarehouseId = payload
  },
  setShelfList: (state, payload) => {
    state.shelfList = payload
  },
  setProductList: (state, payload) => {
    state.productList = payload
  },
  setReadedProductList: (state, payload) => {
      state.readedProductList.push(payload)
  },
  setAllProductListAccordion: (state, payload) => {
    state.allProductListAccordion = payload
  },
  setActiveShelfId(state, payload) {
    state.activeShelfId = payload
    if (payload != null && payload != '') {
      this.dispatch('warehouseTransfer/getProductListInShelf')
    } else {
      this.commit('warehouseTransfer/setProductList', [])
    }
  }
}

const actions = {
  async getFileDetail({ commit }, fileNo) {
    try {
      const response = await Vue.prototype.$axios.get('/api/warehouse-product-transfers', {
        params: {
          fileNo: fileNo
        }
      })
      commit('setFileDetail', response.data.contents[0])
      commit('transferWarehouseId', response.data.contents[0].transferWarehouseId)
    } catch (error) {
      //HATA
    }
  },
  async getShelfList({ state, commit, dispatch }) {
    commit('setActiveShelfId', null)
    commit('setShelfList', [])
    commit('changeBarcodeType', 0)
    state.pickCount = 0
    try {
      const response = await Vue.prototype.$axios.get(`/api/warehouse-product-transfers/${state.fileDetail.id}/pick/shelfs`)
      if (response.data.shelfs.length == 0 & !state.isNotProductButton) {
        await dispatch('completePick')
        return
      }

      if (response.data.shelfs.length == 0 && state.isMoreNotFoundProduct) {
        await router.push({ name: 'DepolarArasiTransfer' })
        state.isMoreNotFoundProduct = false
        setTimeout(() => {
          Message({ type: 'success', message: i18n.t('Dosyanız yönetici tarafından incelenip , tamamlanacaktır') })
        }, 2000);
      }

      if(response.data.shelfs.length == 0){
        console.log("0'landı")
        commit('setProductList', "")
        state.readedProductList = []
        commit('setAllProductListAccordion', "")
        state.pickCount = 0
      }

      commit('setShelfList', response.data.shelfs)
    } catch (error) {
      //HATA
    }
  },
  async getProductListInShelf({ state, commit, dispatch }) {
   
    try {
      const response = await Vue.prototype.$axios.get(`/api/warehouse-product-transfers/${state.fileDetail.id}/pick/shelfs/${state.activeShelfId}/products`)
      await state.pickCount++
      if (response.data.products.length == 0) {
        return await dispatch('getShelfList')
      }

      console.log("pickCount", state.pickCount)
      if (state.pickCount == 1) { //tüm ürünler hep sabit kalsın.
        commit('setAllProductListAccordion', response.data.products)
      }
      commit('setProductList', response.data.products)
    } catch (error) {
      //HATA
    }
  },
  async getProductListForCheck({ commit, state, dispatch }) {
    try {
      const response = await Vue.prototype.$axios.get(`/api/warehouse-product-transfers/${state.fileDetail.id}/check/products`)
      if (response.data.products.length == 0) {
        await dispatch('completeControl')
        await router.push({ name: 'DepolarArasiTransfer' })
        return
      }
      commit('setProductList', response.data.products)
    } catch (error) {
      //HATA
    }
  },
  //ürün işlemleri
  async pickProduct({ commit, state, dispatch }, product) {

    try {
      const response = await Vue.prototype.$axios.put(`/api/warehouse-product-transfers/${state.fileDetail.id}/shelfs/${state.activeShelfId}/products/${product.productId}/pick`, {
        "quantity": 1,
        "isMissing": state.isNotFoundProduct
      })

      commit('setReadedProductList', product)
      Message({ type: 'success', message: i18n.t('UrunBasariylaToplandi') })
      setTimeout(() => {
        beep('yes')
      }, 50);
      await dispatch('getProductListInShelf')
    } catch (error) {
      beep('no')
      //HATA
    }
  },
  async completePick({ state }) {
    try {
      await Vue.prototype.$axios.put(`/api/warehouse-product-transfers/${state.fileDetail.id}/pick/completed`)
      Message({ type: 'success', message: i18n.t('ToplamaBitti') })
      MessageBox.alert('', i18n.t('ToplamaBitti'), {
        center: true,
        showClose: false,
        type: 'success',
        confirmButtonText: i18n.t('ArabayiBirak'),
        callback: action => {
          router.push({ name: 'ToplamaArabasi' })
        }
      })

      await router.push({ name: 'DepolarArasiTransfer' })
    } catch (error) {
      //HATA
    }
  },
  async controlProduct({ state, dispatch }, product) {
    try {
      const response = await Vue.prototype.$axios.put(`/api/warehouse-product-transfers/${state.fileDetail.id}/products/${product.productId}/check`, {
        "quantity": 1
      })
      Message({ type: 'success', message: i18n.t('UrunKontrolEdildi') })
      setTimeout(() => {
        beep('yes')
      }, 50);
      await dispatch('getProductListForCheck')
    } catch (error) {
      beep('no')
      //HATA
    }
  },
  async completeControl({ state }) {
    try {
      const response = await Vue.prototype.$axios.put(`/api/warehouse-product-transfers/${state.fileDetail.id}/check/completed`)
      Message({ type: 'success', message: i18n.t('KaliteKontrolBasariylaYapildi') })
    } catch (error) {
      //HATA
    }
  },
  //list item işlemleri
  async changeRequestStatus({ }, payload) {
    try {
      const response = await Vue.prototype.$axios.put(`/api/warehouse-product-transfers/${payload.id}/status`, {
        status: payload.status
      })
      if (payload.message != null) Message({ type: 'success', message: i18n.t(payload.message) })
    } catch (error) {
      //HATA
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}